import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import { Link, navigate } from "gatsby";
import { useForm, Controller } from "react-hook-form";
import { ref, uploadBytes } from "firebase/storage";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import PageWrapper from "../components/PageWrapper";
import { Select } from "../components/Core";
import RichTextEditor from "../components/RichTextEditor";
import ImageUploader from "../components/ImageUploader";
import { validation } from "../utils/validation";
import { getCountries } from "../utils/getCountries";
import { createAccount, getAccountImage } from "../api";
import notify from "../utils/notify";
import { storage } from "../services/firebase";

const ProfileUpdate = () => {
  const [submitting, setSubmitting] = useState(false);
  const [otherSkills, setOtherSkills] = useState(false);
  const { auth } = useContext(AuthContext);

  const [form, setForm] = useState(null);

  const showForm = (data) => {
    if (data == "client") {
      if (form == "client") {
        setForm(null);
      } else setForm(data);
    }
    if (data == "talent") {
      if (form == "talent") {
        setForm(null);
      } else setForm(data);
    }
  };

  const {
    register,
    errors,
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm();
  const {
    register: registerTalent,
    errors: errorsTalent,
    handleSubmit: handleSubmitTalent,
    reset: resetTalent,
    control: controlTalent,
    setValue: setValueTalent,
    watch: watchTalent,
  } = useForm();
  const countries = getCountries();
  const {
    industries,
    experienceLevels,
    englishProficiencyLevels,
    currencies,
    allSkills,
    getSkills,
    getCategories,
  } = useContext(DataContext);

  const onEditorStateChangeDescription = (data) => {
    setValue("description", data);
  };

  const onEditorStateChange = (data) => {
    setValueTalent("bio", data);
  };

  const watchSkills = watchTalent("skills");

  if (Array.isArray(watchSkills)) {
    const watchMap = watchSkills.map(({ label }) => label);

    if (watchMap.includes("Other") && otherSkills === false) {
      setOtherSkills(true);
    }
    if (!watchMap.includes("Other") && otherSkills === true) {
      setOtherSkills(false);
    }
  }

  // Get skills from subcategories
  const fetchSkills = async () => {
    await getCategories();
    await getSkills();
  };

  useEffect(() => {
    fetchSkills();
    // return () => setSubmitting(false);
  }, []);

  const onImageUploadChange = (data) => {
    setValue("logo", data[0].file);
  };

  const onTalentImageUploadChange = (data) => {
    setValueTalent("picture", data[0].file);
  };

  const onClientSubmit = async (data) => {
    setSubmitting(true);
    try {
      let imageUrl = "";

      const account_id = localStorage.getItem("account_id");

      if (data.logo) {
        const imageRef = ref(storage, `logos/${account_id}`);
        await uploadBytes(imageRef, data.logo);
        //console.log("Logo upload successful");
        // Get logo url
        imageUrl = await getAccountImage(account_id);
      }

      const country = data.country.label;
      const industry = data.industry.label;
      const formData = _.omit(data, "country", "logo", "industry");
      const payload = {
        account_id,
        country,
        imageUrl,
        industry,
        ...formData,
      };
      const response = await createAccount(account_id, "Client", payload);
      if (response) {
        reset();
        notify("Profile updated successfully", "success");
        setTimeout(() => {
          // setSubmitting(false);
          navigate("/dashboard-main", { replace: true });
        }, 2000);
      }
      setSubmitting(false);
    } catch (error) {
      notify(error.message);
      console.log(error);
      setSubmitting(false);
    }
  };

  const onTalentSubmit = async (data) => {
    setSubmitting(true);
    try {
      const account_id = localStorage.getItem("account_id");
      const country = data.country.label;
      const experience = data.experience.label;
      const english = data.english.label;
      const skills = data.skills.map(({ label }) => label);
      const rate = data?.rate ? parseInt(data.rate) : null;
      const hoursavailable = data?.hoursavailable
        ? parseInt(data.hoursavailable)
        : null;

      const currency = data.currency.label;

      let imageUrl = "";

      if (data.picture) {
        const imageRef = ref(storage, `images/${account_id}`);
        await uploadBytes(imageRef, data.picture);

        // Get picture url
        imageUrl = await getAccountImage(account_id);
      }

      // console.log(data);
      const educationDegree = data.educationDegreeName;
      const educationUniversity = data.educationUniversity;
      const educationYear = data.educationYear;
      const educationCountry = data.educationCountry.label;

      const formData = _.omit(data, [
        "country",
        "experience",
        "skills",
        "english",
        "rate",
        "hoursavailable",
        "currency",
        "picture",
        "educationCountry",
        "educationYear",
        "educationUniversity",
        "educationDegreeName",
      ]);
      const payload = {
        account_id,
        country,
        experience,
        english,
        skills,
        rate,
        hoursavailable,
        currency,
        imageUrl,
        featured: false,
        educationDegree,
        educationUniversity,
        educationYear,
        educationCountry,
        ...formData,
      };

      const response = await createAccount(account_id, "Talent", payload);
      if (response) {
        resetTalent();
        notify("Profile updated successfully", "success");
        setTimeout(() => {
          // setSubmitting(false);
          navigate("/dashboard-main", { replace: true });
        }, 2000);
      }
      setSubmitting(false);
    } catch (error) {
      notify(error.message);
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper headerConfig={auth ? { button: "profile" } : null}>
        <div className="bg-default-2 pt-25 pb-13 pb-xxl-32">
          <div className="container">
            <div className="mb-18">
              <div className="row my-11">
                <div className="col-12 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0 text-center text-uppercase">
                    Complete your profile to continue
                  </h3>
                </div>
              </div>

              <div className="bg-white py-12 shadow-8 rounded-0 d-flex flex-md-row flex-column mb-0 justify-content-center">
                <div
                  className={`media ${
                    form == "client" ? "bg-yellow-opacity-1" : "bg-white"
                  } rounded-4 pl-8 pt-9 pb-9 pr-8 hover-shadow-1 mb-9 shadow-8 border-0 mx-5`}
                  onClick={() => showForm("client")}
                >
                  <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-building"></i>
                  </div>
                  <div className="">
                    <p className="text-uppercase font-size-4 font-weight-bold text-default-color py-0 px-0 mb-0">
                      Continue as a Client
                    </p>
                    <span className="font-size-2">
                      post jobs, tasks, hire talents...
                    </span>
                  </div>
                </div>

                <div
                  className={`media ${
                    form == "talent" ? "bg-yellow-opacity-1" : "bg-white"
                  } rounded-4 pl-8 pt-9 pb-9 pr-7 hover-shadow-1 mb-9 shadow-8 border-0 mx-5`}
                  onClick={() => showForm("talent")}
                >
                  <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                    <i className="fas fa-user"></i>
                  </div>
                  <div className="">
                    <p className="text-uppercase font-size-4 font-weight-bold text-default-color py-0 px-0 mb-0">
                      Continue as a Talent
                    </p>
                    <span className="font-size-2">
                      work on exciting jobs, tasks...
                    </span>
                  </div>
                </div>
              </div>

              {form && form === "client" ? (
                <div
                  className={`bg-white shadow-8 rounded-0 mb-11 py-sm-12 px-6 px-sm-11 `}
                >
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 order-2 order-xl-1 ">
                      <h3 className="font-size-6 mb-0 text-center text-uppercase">
                        Client Profile
                      </h3>
                      <div className={`bg-white p-lg-11 mt-8`}>
                        <form
                          key={1}
                          autoComplete="off"
                          onSubmit={handleSubmit(onClientSubmit)}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="firstname"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  id="firstname"
                                  name="firstname"
                                  ref={register(validation.firstname)}
                                />
                                <span className="text-danger small">
                                  {errors?.firstname?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="lastname"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  id="lastname"
                                  name="lastname"
                                  ref={register(validation.lastname)}
                                />
                                <span className="text-danger small">
                                  {errors?.lastname?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="email"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Email*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email Address"
                                  id="email"
                                  name="email"
                                  ref={register(validation.email)}
                                />
                                <span className="text-danger small">
                                  {errors?.email?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="company"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Company Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Company Name"
                                  id="company"
                                  name="company"
                                  ref={register(validation.company)}
                                />
                                <span className="text-danger small">
                                  {errors?.company?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="description"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Company Description*
                                </label>

                                <Controller
                                  name="description"
                                  control={control}
                                  defaultValue=""
                                  rules={validation.companyDescription}
                                  as={
                                    <RichTextEditor
                                      onEditorStateChange={
                                        onEditorStateChangeDescription
                                      }
                                      name="description"
                                      placeholder="Enter Company description in RichText"
                                      ref={register(
                                        validation.companyDescription
                                      )}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errors?.description?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="website"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Company Website
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Company Website URL (Optional)"
                                  id="website"
                                  name="website"
                                  ref={register(validation.website)}
                                />
                                <span className="text-danger small">
                                  {errors?.website?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="linkedin"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  LinkedIn Profile (Optional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder="Enter LinkedIn profile URL (Optional)"
                                  id="linkedin"
                                  name="linkedin"
                                  ref={register(validation.linkedin)}
                                />
                                <span className="text-danger small">
                                  {errors?.linkedin?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="github"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Github (Optional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder="Enter Twitter Github URL (Optional)"
                                  id="github"
                                  name="github"
                                  ref={register(validation.companyGithub)}
                                />
                                <span className="text-danger small">
                                  {errors?.github?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="twitter"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Twitter Profile (Optional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue=""
                                  placeholder="Enter Twitter profile URL (Optional)"
                                  id="twitter"
                                  name="twitter"
                                  ref={register(validation.twitter)}
                                />
                                <span className="text-danger small">
                                  {errors?.twitter?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="industry"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Industry*
                                </label>

                                <Controller
                                  name="industry"
                                  control={control}
                                  defaultValue={""}
                                  rules={validation.industry}
                                  as={
                                    <Select
                                      options={industries}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                      border={false}
                                    />
                                  }
                                />

                                <span className="text-danger small">
                                  {errors?.industry?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="address"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Location*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address or Company Address"
                                  id="address"
                                  name="address"
                                  ref={register(validation.location)}
                                />
                                <span className="text-danger small">
                                  {errors?.address?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="city"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  City*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  id="city"
                                  name="city"
                                  ref={register(validation.city)}
                                />
                                <span className="text-danger small">
                                  {errors?.city?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="country"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Country*
                                </label>
                                <Controller
                                  name="country"
                                  control={control}
                                  defaultValue=""
                                  rules={validation.country}
                                  as={
                                    <Select
                                      options={countries}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                      border={false}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errors?.country?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="logo"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Company Logo
                                </label>
                                <Controller
                                  name="logo"
                                  control={control}
                                  defaultValue=""
                                  rules={validation.logo}
                                  as={
                                    <ImageUploader
                                      onImageUploadChange={onImageUploadChange}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errors?.logo?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="form-group mb-8 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-medium rounded-5 text-uppercase w-100"
                                  disabled={submitting ? true : false}
                                >
                                  {submitting
                                    ? "Updating profile..."
                                    : "Continue"}
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                {errors &&
                                  Object.values(errors).map((field, index) => (
                                    <p
                                      key={index}
                                      className="text-danger small m-0 p-0"
                                    >
                                      {field.message}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : form && form === "talent" ? (
                <div
                  className={`bg-white shadow-8 rounded py-8 py-sm-12 px-6 px-sm-11`}
                >
                  <div className="row justify-content-center">
                    <div className="col-12 col-sm-6 order-2 order-xl-1 ">
                      <h3 className="font-size-6 mb-0 text-center text-uppercase">
                        Talent Profile
                      </h3>
                      <div className={`bg-white p-lg-11 mt-8`}>
                        <form
                          key={2}
                          autoComplete="off"
                          onSubmit={handleSubmitTalent(onTalentSubmit)}
                        >
                          <div className="row align-items-center">
                            <div className="col-lg-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="firstname"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  First Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="First Name"
                                  id="firstname"
                                  name="firstname"
                                  ref={registerTalent(validation.firstname)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.firstname?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="lastname"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Last Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Last Name"
                                  id="lastname"
                                  name="lastname"
                                  ref={registerTalent(validation.lastname)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.lastname?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="email"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Email*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email Address"
                                  id="email"
                                  name="email"
                                  ref={registerTalent(validation.email)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.email?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="title"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Title*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Title (e.g Blockchain Developer)"
                                  id="title"
                                  name="title"
                                  ref={registerTalent(validation.title)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.title?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="bio"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Bio*
                                </label>
                                <Controller
                                  name="bio"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.bio}
                                  as={
                                    <RichTextEditor
                                      onEditorStateChange={onEditorStateChange}
                                      placeholder="Enter a short bio about yourself"
                                      name="bio"
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.bio?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="experience"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Experience Level*
                                </label>
                                <Controller
                                  name="experience"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.experience}
                                  as={
                                    <Select
                                      options={experienceLevels}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                      border={false}
                                      id="experience"
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.experience?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="skills"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Skills*
                                </label>
                                <Controller
                                  name="skills"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.skills}
                                  as={
                                    <Select
                                      options={allSkills}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control pt-8 pb-24 pb-sm-22 border-0 basic-multi-select"
                                      classNamePrefix="select"
                                      border={false}
                                      isMulti
                                    />
                                  }
                                />
                                <span className="small mb-2">
                                  Select 'Other' if your skills are not listed.
                                  Enter your skill in the 'Other Skills' field.
                                </span>
                                <span className="text-danger small">
                                  {errorsTalent?.skills?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          {otherSkills ? (
                            <div className="row align-items-center">
                              <div className="col-12">
                                <div className="form-group mb-8">
                                  <label
                                    htmlFor="otherSkills"
                                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                  >
                                    Other skills
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Other Skill"
                                    id="otherskills"
                                    name="otherSkills"
                                    ref={registerTalent(validation.otherSkills)}
                                  />
                                  <span className="text-danger small">
                                    {errorsTalent?.otherSkills?.message || ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="education"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Education Details*
                                </label>
                                <div>
                                  <label
                                    htmlFor="education"
                                    className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                                  >
                                    Name of Degree
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Degree name e.g Masters in Software Engineering"
                                  id="educationDegreeName"
                                  name="educationDegreeName"
                                  ref={registerTalent(
                                    validation.educationDegreeName
                                  )}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.educationDegreeName?.message ||
                                    ""}
                                </span>
                              </div>

                              <div className="form-group mb-8">
                                <div>
                                  <label
                                    htmlFor="education"
                                    className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                                  >
                                    Name of University
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="University e.g University of Pretoria"
                                  id="educationUniversity"
                                  name="educationUniversity"
                                  ref={registerTalent(
                                    validation.educationUniversity
                                  )}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.educationUniversity?.message ||
                                    ""}
                                </span>
                              </div>
                              <div className="form-group mb-8">
                                <div>
                                  <label
                                    htmlFor="education"
                                    className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                                  >
                                    Year of completion
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Year of completion e.g (08-2018)"
                                  id="educationYear"
                                  name="educationYear"
                                  ref={registerTalent(validation.educationYear)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.educationYear?.message || ""}
                                </span>
                              </div>
                              <div className="form-group mb-8">
                                <div>
                                  <label
                                    htmlFor="education"
                                    className="font-size-3 text-black-2 font-weight-semibold line-height-reset"
                                  >
                                    Country of study
                                  </label>
                                </div>
                                <Controller
                                  name="educationCountry"
                                  control={controlTalent}
                                  defaultValue="Country of education"
                                  rules={validation.country}
                                  as={
                                    <Select
                                      options={countries}
                                      className="pl-0 h-100 arrow-3 arrow-3-black min-width-px-273  text-black-2 d-flex align-items-center w-100"
                                      border={true}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.country?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="english"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  English Proficiency*
                                </label>
                                <Controller
                                  name="english"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.english}
                                  as={
                                    <Select
                                      options={englishProficiencyLevels}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                      border={false}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.english?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="rate"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Hourly Rate*
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="rate"
                                  name="rate"
                                  min={20}
                                  placeholder="(e.g 45)"
                                  ref={registerTalent(validation.rate)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.rate?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="currency"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Currency*
                                </label>
                                <Controller
                                  name="currency"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.currency}
                                  as={
                                    <Select
                                      options={currencies.filter(
                                        (currency) =>
                                          currency.label == "NEAR" ||
                                          currency.label == "USDT"
                                      )}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-284 text-black-2 d-flex align-items-center  form-control"
                                      border={false}
                                      id="currency"
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.currency?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="hoursavailable"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Hours of Availability*
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter number of hours"
                                  id="hoursavailable"
                                  name="hoursavailable"
                                  min={1}
                                  max={24}
                                  ref={registerTalent(
                                    validation.hoursavailable
                                  )}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.hoursavailable?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="address"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Location*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Address"
                                  id="address"
                                  name="address"
                                  ref={registerTalent(validation.location)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.address?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="city"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  City*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="City"
                                  id="city"
                                  name="city"
                                  ref={registerTalent(validation.city)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.city?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="country"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Country*
                                </label>
                                <Controller
                                  name="country"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.country}
                                  as={
                                    <Select
                                      options={countries}
                                      className="pl-0 arrow-3 arrow-3-black min-width-px-273 text-black-2 d-flex align-items-center form-control"
                                      border={false}
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.country?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="postalcode"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Postal Code*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Postal Code/Zip Code"
                                  id="postalcode"
                                  name="postalcode"
                                  ref={registerTalent(validation.postalcode)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.postalcode?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="phone"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Phone Number*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone number"
                                  id="phone"
                                  name="phone"
                                  ref={registerTalent(validation.phone)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.phone?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="github"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Github Profile (Optional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Github profile URL"
                                  id="github"
                                  name="github"
                                  ref={registerTalent(validation.github)}
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.github?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                <label
                                  htmlFor="picture"
                                  className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                >
                                  Profile Picture
                                </label>
                                <Controller
                                  name="picture"
                                  control={controlTalent}
                                  defaultValue=""
                                  rules={validation.logo}
                                  as={
                                    <ImageUploader
                                      onImageUploadChange={
                                        onTalentImageUploadChange
                                      }
                                    />
                                  }
                                />
                                <span className="text-danger small">
                                  {errorsTalent?.picture?.message || ""}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12">
                              <div className="form-group mb-8 text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-medium rounded-5 text-uppercase w-100 focus-reset"
                                  disabled={submitting ? true : false}
                                >
                                  {submitting
                                    ? "Updating profile..."
                                    : "Continue"}
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="row align-items-center">
                            <div className="col-12">
                              <div className="form-group mb-8">
                                {errorsTalent &&
                                  Object.values(errorsTalent).map(
                                    (field, index) => (
                                      <p
                                        key={index}
                                        className="text-danger small m-0 p-0"
                                      >
                                        {field.message}
                                      </p>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default ProfileUpdate;
